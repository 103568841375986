
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.search-bar-attached-filters {
    position: absolute;
    top: calc(100% + 0px); // Adjust the top relative to the search-bar
    width: 100%;
    background-color: $itg-white;
    border: .15rem solid $itg-grey;
    border-radius: .5rem;
    box-shadow: .1rem solid rgba($itg-grey--mid,0.25);
    padding: 1rem;
    z-index: 1;

    &__form-container {
        display: flex;
        width: 100%;
    }
}