
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
$itg-border-color: #B2BCCA;

.home-search-bar {
    display: flex;
    height: 2.7rem; // ~42px
    font-family: $itg-font-text;
    margin: .5rem 0;

    &--exported {
        max-width: 100% !important;
    }

    // As the screen gets smaller allow the searchbar more room
    @media (max-width: $screen-lg) {
        max-width: 95%;
    }

    :global(.react-autosuggest__container) {
        flex-grow: 2;
        max-height: 4rem;
    }

    &__autocomplete {
        background-color: $itg-white;
        border: .06rem solid $itg-grey--mid-4;
        border-radius: .2rem; // ~ 6px
        // Specifically 998 to avoid the search bar sitting over the menu dropdowns on the homepage
        z-index: 998;
        display: flex;
        height: 100%;

        &:hover,
        &:active,
        &:focus {
            // When user interacts, apply the orange border with
            // transition time equal to that of the button.
            border: 2px solid lighten($itg-orange, 15%);
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        // Configure the button inside the "Searchbar"
        &__button {
            margin: 0.5rem;
            padding: .5rem;
            border: none;
            border-radius: .38rem; // ~ 6px
            background-color: $itg-orange;
            cursor: pointer;
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 12rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover,
            &:active,
            &:focus {
                background: $itg-black;
                color: $itg-orange;
                font-weight: bold;
            }
            
            // hide the btn for searches that require the search btn to be outside the search bar
            &--hide {
                display: none;
            }
            
            // On smaller screens just show button as 20% of the size.
            @media (max-width: $screen-md) {
                border-top-right-radius: .3rem;
                border-bottom-right-radius: .3rem;
                margin: 0;
                width: 10%;
                background-color: transparent;

                &:hover,
                &:active,
                &:focus {
                    background: none;
                }
            }

            &__icon {
                font-size: larger;
                margin-right: .2rem;
                color: $itg-white;

                @media (max-width: $screen-md) {
                    color: $itg-orange;
                    font-size: x-large;

                    &:hover{
                        color: $itg-black;
                    }
                }
            }

            &__search-text {
                margin-left: 0rem;

                @media (max-width: $screen-md) {
                    display: none;
                }
            }
        }

        input {
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            padding-left: 1rem;
            margin-bottom: 2px;
            flex-grow: 2;
            border: none;
            text-overflow: ellipsis !important;
            font-size: 1rem; // ~12pt.

            ::placeholder {
                color: $itg-grey;
            }

            // On smaller screens just show as 80% of the size.
            @media (max-width: $screen-md) {
                width: 80%;
            }

            // On tiny screens just reduce the text size.
            @media (max-width: $screen-xxs) {
                font-size: 14pt;
            }
        }

        &--suggesting {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-bottom-width: 2px;
            border: 2px solid lighten($itg-orange, 15%) !important;
        }

        &__suggestion-container {
            z-index: 997;
            box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.10), 0 2px 0 0 rgba(0, 0, 0, 0.12);
            border-left: 2px solid lighten($itg-orange, 5%) !important;
            border-right: 2px solid lighten($itg-orange, 5%) !important;
            border-bottom: 2px solid lighten($itg-orange, 5%) !important;
            box-shadow:
            0 2px 4px 0 rgba(0, 0, 0, 0.10),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
        }
    }
}