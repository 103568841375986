
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
$radius: 1.2rem;

.year-picker {
    position: relative;
    display: flex;

    &__modal {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $itg-grey--light;
        border: .15rem solid $itg-grey;
        z-index: 9999999;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &__list {
        max-height: 12.5rem; // ~200px
        overflow-y: auto;
        overflow-x: hidden;
        
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(3.1rem, 1fr));
        justify-items: stretch;
        align-items: center;
        justify-content: space-around;
        margin: 1rem 0;
    }

    &__year-item {
        cursor: pointer;
        padding: .4rem;
        margin: .5rem 0;

        &--active {
            background-color: transparentize($itg-blue--light, 0.15);
            color: $itg-white;
            font-weight: 700;
            border-radius: $radius;

            &--start {
                border-radius: $radius 0 0 $radius;
            }
    
            &--between {
                border-radius: 0;
            }
    
            &--end {
                border-radius: 0 $radius $radius 0;
            }
        }        
    }
}