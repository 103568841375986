
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.company-search {
    &__form {
        &__field {
            min-width: 12rem !important;
            max-height: 3rem;

            @media (max-width: $screen-sm) {
                width: 100%;
            }
        }

    }

    &__select {
        padding-bottom: .2rem;
    }
}