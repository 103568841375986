
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-statemodal {
    &__body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__button {
        // I have applied !important here because the precedence of MUI styles gets mucked up on the WP site.
        border-radius: .3rem !important;
        height: 2.4rem !important;
        min-width: 7rem !important;
        margin: .5rem !important;

        @media (max-width: $screen-md) {
            margin: .5rem 0 !important;
            width: 100% !important;
        }
    }
}