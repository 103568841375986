
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-dialog {
    // So background colour takes full effect.
    h2 {
        background-color: transparent;
    }

    // Se the default background to muted blue
    &__paper {
        background-color: $itg-blue--muted;
    }


    &__content {
        &_root {
            // Adjust the dialog paper root padding, so the product cards fit better on sm screens
            @media (max-width: $screen-sm) {
                padding: 1rem .5rem;
            }
        }
    }

    &__title {
        text-align: center;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__subtitle {
        font-size: $desktop-body-font-size-med;
        color: $itg-black;
        margin-bottom: 1rem;
    }

    &__close-button {
        color: $itg-grey;
        box-shadow: none !important;
        height: 2.5rem;
        border-radius: 0.5rem;
        min-width: unset;
    }
}