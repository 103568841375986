
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.search-bar-suggestion-container {
    position: absolute;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    width: 100%;
    background-color: $itg-white;
    z-index: 999;

    // To adjust the react autosuggest styles.
    li {
        list-style-type: none;
    }

    ul {
        padding: 0rem;
        margin-top: 0rem;
    }

    &__powered-by-google {
        width: 6rem;
        position: absolute;
        bottom: 0.3rem;
        right: 1rem;
        z-index: 50;

        @media (max-width: $mobile) {
            bottom: 0.2rem;
        }
    }
}