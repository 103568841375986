
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.search-bar-suggestion-container-result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem 1.5rem;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: $itg-grey--light;

    background-color: $itg-white;

    font-size: $mobile-body-font-size-med;

    cursor: pointer;

    @media (max-width: $screen-xs) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &__icon {
        text-align: center;
        color: $itg-black;

        @media (max-width: $screen-sm) {
            width: 0.5rem;
        }
    }

    &__text {
        padding-left: 1.4rem;
        color: $itg-black;

        @media (max-width: $screen-xs) {
            font-size: calc($mobile-body-font-size-min + 2pt);
            top: 0.08rem;
        }
    }

    &:hover {
        background-color: $itg-grey--light;
    }

    &--highlighted {
        background-color: $itg-grey--light;
    }

    &--none {
        display: none;
    }
}