
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.vertical-search-btn {
    min-height: 2.6rem; // ~42px
    bottom: .1rem;
    margin: .5rem 0 !important; // Overwrite mui btn margins

    @media (max-width: $screen-sm) {
        width: 100%;
    }
}