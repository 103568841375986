
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.search-bar-autocomplete-content {
    // To eliminate WP border-radius when exported
    &__filter-btn {
        border-radius: 0 !important;
    }
    // To adjust the react autosuggest styles.
    li {
        list-style-type: none;
    }

    ul {
        padding: 0rem;
        margin-top: 0rem;
    }

    // Overwrite WP button and svg selectors
    button, svg {
        outline: none !important;
    }
}
