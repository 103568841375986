
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.person-search {
    &__form {
        margin-bottom: .5rem;
        align-items: flex-start;

        &__checkbox {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            // Custom Checkbox border and spacing
            border: .1rem solid $itg-grey--mid-4;
            border-radius: 0.3rem;

            // Compensates the extra spacing inside the input
            vertical-align: top;
            margin-top: .5rem;
            height: 2.5rem;
            padding-right: 1rem;

            & > * {
                color: $itg-grey--mid-3;
                svg {
                    color: $itg-grey--mid-3;
                }
            }
        }

        @media (max-width: $screen-md) {
            margin-bottom: 1rem;
        }

        &__item {
            color: $itg-grey--mid-3;
                
            fieldset {
                border-color:$itg-grey--mid-4;

                &:hover {
                    border-color: $itg-orange;
                }
            }

            @media (max-width: $screen-md) {
                width: 100%;
            }

            &__input {
                // Remove any borders for the input only (Applicable only for the WordPress default css)
                input[type=text] {
                    border: none !important;
                }
                
                & > * {
                    color: $itg-grey--mid-3;

                    svg {
                        color: $itg-grey--mid-3;
                    }
                }
                @media (max-width: $screen-md) {
                    flex: 1 1 auto;
                }
            }
        }
    }
}