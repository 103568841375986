
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.vertical-search {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;

    &__bar {
        display: flex;

        // This is so the Search-bar aligns with any mui text field
        max-height: 2.5rem;
        margin-bottom: .8rem;
        
        flex: 2 1 auto;

        @media (max-width: $screen-sm) {
            max-width: 100%;
        }
    }
    
    &__form {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;

        @media (max-width: $screen-md) {
            gap: 1rem;
        }

        &__field {
            flex: 1 1 auto;
            // fixed height allow extra space for <p> validations to be displayed bellow the field
            height: 3.5rem;
            
            & > * {
                color: $itg-grey--mid-3;
                
                fieldset {
                    border-color:$itg-grey--mid-4;

                    &:hover {
                        border-color: $itg-orange;
                    }
                }
            }
        }
    }

    &__btn {
        // give that extra space in case error validations are 
        // displayed and the form is shown in a single line
        margin-bottom: .8rem;
    }
}